//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BottomSheet from '$components/ui/bottom-sheet.vue';
import logoWhatsapp from '$resources/images/whatsapp.png';
import Button from './button.vue';

export default {
  components: {
    BottomSheet,
    Button
  },
  filters: {
    formatNumber(value) {
      return new Intl.NumberFormat('de-DE').format(Number(value).toFixed(2));
    }
  },
  props: {
    offer: Object
  },
  data: () => ({
    logoWhatsapp,
    showBottomSheet: true,
    loading: false
  }),
  methods: {
    onContinue() {
      this.loading = true;
      this.$emit('continue');
    },
    openWhatsapp() {
      window.open('https://wa.me/+34622330385?text=INFO', '_blank');
    },
    resetLoading() {
      this.loading = false;
    }
  }
};
